import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const EarnNav = () => {
  const location = useLocation();

  return (
    <nav className="section has-background-light pt-3 pb-0 has-border-bottom is-hidden-mobile">
      <div className="container">
        <div className="tabs mb-npx">
          <ul>
            <li className={ location.pathname === '/farms' ? 'is-active' : '' }>
              <Link to="/farms">
                Farms
                <span className="tag is-small is-warning ml-2">finished</span>
              </Link>
            </li>
            <li className={ location.pathname === '/pools' ? 'is-active' : '' }>
              <Link to="/pools">
                Pools
                <span className="tag is-small is-warning ml-2">finished</span>
              </Link>
            </li>
            <li className={ location.pathname === '/walls' ? 'is-active' : '' }>
              <Link to="/walls">
                Walls
                {/* <span className="tag is-small is-primary ml-2">live</span> */}
              </Link>
            </li>
            <li className={ location.pathname === '/vaults' ? 'is-active' : '' }>
              <Link to="/vaults">
                Vaults
                {/* <span className="tag is-small is-primary ml-2">live</span> */}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default EarnNav;
